<template>
  <section id="MeetTheChef">
    <div class="chef">
      <div class="chef-name">
        Sean Kinoshita<br />
        <span>Senior Executive Chef</span>
      </div>
      <div class="chef-points">
        <img src="@/assets/icons/Food/star-icon.svg" alt="star" />
        <p>
          Formerly Executive Chef with Caesar’s Entertainment, Executive Chef &
          Chef de Cuisine Tao Asian Bistro, Executive Chef of Jacksonville
          Jaguars before joining the Allegiant Stadium Silver & Black
          Hospitality
        </p>
      </div>
      <div class="chef-points">
        <img src="@/assets/icons/Food/knife-icon.svg" alt="knife" />
        <p>
          Host Chef for the Kapalua Food and Wine Symposium, Atlantic Food and
          Wine Festival, Uncork’d by Bon Appetit Las Vegas, and Beverly Hills
          Food and Wine Festival. Guest Chef at James Beard House in NYC, Las
          Vegas Food and Wine Festival, Las Vegas Events Life is Beautiful and
          Project Dinner Table.
        </p>
      </div>
      <div class="chef-points">
        <img src="@/assets/icons/Food/world-icon.svg" alt="world" />
        <p>
          Graduate of California Culinary Academy & spent his early years
          training with renowned chefs in New York (Gray Kunz at Lespinasse and
          David Burke at Park Avenue Café) and San Francisco (Ken Oringer and
          Ming Tsai at Mandarin Oriental) prior to returning to the islands to
          perfect his sushi preparation and culinary management skills
        </p>
      </div>
      <div class="chef-points last">
        <img src="@/assets/icons/Food/heart.svg" alt="fish" />
        <p>
          Chef Kinoshita is passionate about food – the taste, the technique,
          and the tradition. He is excited to bring his expertise, experience,
          and enthusiasm to Levy Restaurants.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MeetTheChef",
};
</script>

<style lang="scss" scoped>
#MeetTheChef {
  height: 100vh;
  width: 100vw;
  background-image: url("~@/assets/images/Food/chef-bg-2.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  @media (max-width: 993px) {
    background-image: none;
    min-height: auto;
  }
  .chef {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 190px 30px 160px 351px;
    @media (max-width: 993px) {
      margin-left: 0;
      padding-left: 0;
      // display: flex;
      // flex-direction: column;
    }
    &-name {
      position: relative;
      font-family: "ArtegraSans-Bold";
      font-size: 35px;
      color: #ffffff;
      letter-spacing: 2.19px;
      line-height: 30px;
      text-transform: uppercase;
      margin-bottom: 27px;
      @media (max-width: $lg) {
        font-size: 28px;
        letter-spacing: 1.75px;
        line-height: 27px;
        margin-left: 100px;
      }
      @media (max-width: $md) {
        margin-left: 50px;
      }
      @media (max-width: $sm) {
        margin-left: 20px;
      }
      span {
        font-size: 20px;
        color: #989898;
        letter-spacing: 1.25px;
        @media (max-width: $lg) {
          font-size: 18px;
          color: #ffffff;
          letter-spacing: 1.12px;
          line-height: 30px;
        }
      }
    }
    &-points {
      display: flex;
      flex-direction: row;
      margin-bottom: 40px;
      @media (max-width: $lg) {
        margin-left: 100px;
      }
      @media (max-width: $md) {
        margin-left: 50px;
      }
      @media (max-width: $sm) {
        margin-left: 20px;
      }
      img {
        height: 45px;
      }
      p {
        width: 434px;
        font-family: "ArtegraSans-Regular";
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 1.07px;
        line-height: 23px;
        margin-left: 28px;
        @media (max-width: $lg) {
          font-size: 12px;
          letter-spacing: 0.8px;
          line-height: 19px;
        }
      }
    }
    .last {
      margin: 0;
      @media (max-width: 992px) {
        margin-left: 100px;
      }
      @media (max-width: $md) {
        margin-left: 50px;
      }
      @media (max-width: $sm) {
        margin-left: 20px;
      }
    }
  }
}
</style>